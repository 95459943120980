<template>
  <div class="payroll-deduction-scheme--benefits-employers">
    <h1>Benefits for Employers</h1>
    <p>Our Payroll Deduction Scheme is the perfect way for employers to help boost their employees' financial resilience with no cost to you.</p>
    <p>One in five employees say work performance is affected by money worries (<a href="https://employernews.co.uk/news/" target="_blank">https://employernews.co.uk/news/</a>).</p>
    <p>Helping employees' get control over their finances and improve their overall financial wellbeing could lead to business benefits such as increased performance, productivity and reduced absenteeism.</p>
    <p>Help your employees help themselves by setting up a Payroll Deduction Scheme with Cash Box Credit Union.</p>
    <div class="pb-3">
      <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/UhIWNPo482o?si=vOKfhnacT8kzDC9s"
          allowfullscreen
      ></b-embed>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payroll Deduction Scheme Benefits Employers',
  metaInfo() {
    return {
      title: 'Benefits for Employers | Payroll Deduction Scheme | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "payroll, deduction, scheme, benefits, employers" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
